$mainColor: #D8E4DE;
$backgroundColor: #0a1a12; 
$sidebarColor: #0D2318;
$accentColor: #204d36;

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content
    }
}