* {
    margin: 0;
    font-family: 'Merriweather', serif;
}

.app {
    height: 100vh;
    background-color:black;

    .sections {
        overflow: scroll;

        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; // for firefox
        &::-webkit-scrollbar {
            display:none;   // for chrome
        }


        /* For each section inside of .sections class */
        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}