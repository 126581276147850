@import "../../global.scss";

.card-container {
    width: 300px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in-out;
    margin: 20px;
    background-color:$mainColor;
    color:black;
    border-radius: 10px;
    padding-bottom: 10px;
}

.card-container:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}

.image-container {
    overflow: hidden;
    height: 200px;
    display: flex;
    justify-content: center;
}

.card-content {
    margin: 1rem;
    margin-top: 0.5rem;
}

h3, p {
    margin: 0;
    padding: 0;
}

.card-title {
    margin-bottom: 0.5rem;
}
.btn {
    display: flex;
    justify-content: center;
}

.btn button {
    padding: 0.5rem;
    background-color: white;
    border: none;
    transition: 0.2s;
    margin-bottom: 0.5rem;
    border-radius: 3px;
}

.btn button:hover {
    background:rgba(27, 156, 252, 0.1);
    transform: scale(1.5);
}

a {
    text-transform: uppercase;
    color: $sidebarColor;
    text-decoration: none;
    font-weight: bold;
}