@import "../../global.scss";

.intro {
    background-color: transparent;
    color: $mainColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    .covervid {
      min-width: 100%;
      min-height: 100%;
      right: 0;
      bottom: 0;
        height: auto;
        width:auto;
        position: relative;
        background-size: fill;
        overflow: hidden;
        z-index: 0;
    }
  
    @include mobile{
      flex-direction: column;
      align-items: center;
    }

    .center {
        color:white;
        flex: 0.5;
        position: absolute;
  
      .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        @include mobile{
          padding-left: 0;
          align-items: center;
        }
  
        h1 {
          font-size: 60px;
          margin: 10px 10px;
  
          @include mobile{
            font-size: 40px;
          }
        }
        h2 {
          font-size: 35px;
        }
        h3 {
          font-size: 30px;
  
          @include mobile{
            font-size: 20px;
          }
  
          span {
            font-size: inherit;
            color: white;
          }
  
          .ityped-cursor {
            animation: blink 1s infinite;
          }
  
          @keyframes blink {
              50%{
                  opacity: 1;
              }
              100%{
                  opacity: 0;
              }
          }
        }
        p {
          align-items: center;
          margin:20px;
          font-size: 20px;
        }
      }
      a {
        display: flex;
        justify-content: center;
        bottom: 70px;
        left: 40%;
        img {
            padding-top: 50px;
            width: 30px;
            animation: arrowBlink 2s infinite;
        }
      }
    }
  }