@import "../../global.scss";

.contact {
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center;

  @include mobile {
    flex-direction: column;
  }

  h1 {
    justify-content: center;
    color: $mainColor;
    margin: 50px;

  }

  Form {
    color: $mainColor;
    flex: 1;
    display: flex;
    overflow: hidden;
    width: 50%;
    height: 50%;
    flex-direction: column;

    .form-label {
      font-size: 30px;
      margin: 10px;
    }
    input {
      border-radius: 10px;
      height: 30px;
      width: 100%;
    }

    textarea {
      width: 100%;
      height: 300px;
      border-radius: 10px;
    }
    Button {
      width: 150px;
      height: 30px;
      color: white;
      background-color: $accentColor;
      border: none;
      border-radius: 10px;
      position: relative;
      font-weight: 500;
      margin-left: 10px;
  
      cursor: pointer;
  
      &:focus {
        outline: none;
      }
    }

  }

}